import { Provider } from 'react-redux'
import { Routing } from 'pages'
import { withProviders } from './providers'
// Стили библиотеки для просмотра фотографий
import 'react-photo-view/dist/react-photo-view.css'
import store, {persistor} from './store'
import AuthProvider from './providers/AuthProvider/ui/AuthProvider'
import './styles/app.scss'
import styles from './styles/App.module.scss'

import { PersistGate } from 'redux-persist/integration/react'

function App() {
  // localStorage.setItem('token', "8dcc2faa4b408a651d996ffd72f24736392dc3db")
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <AuthProvider>
          <main className={''}>
            <Routing />
          </main>
        </AuthProvider>
      </PersistGate>
    </Provider>
  )
}

export default withProviders(App)
export { AuthProvider }
