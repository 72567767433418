import { memo } from 'react'
import styles from './Btn.module.scss'

interface Props {
  // Attributes
  type?: 'tonal' | 'outlined' | 'text'
  width?: number | string
  height?: number | string
  color?:
    | 'purple-main'
    | 'black-main'
    | 'secondary-black'
    | 'grey'
    | 'button-red'
    | 'icons-fill'
    | 'white-main'
  className?: string
  typeBtn?: 'button' | 'submit' | 'reset'
  children?: JSX.Element | string
  // Events
  onClick?: (e?) => void
  disabled?: boolean
  url?: string
  form?: string,
}

const Btn = memo((props: Props) => {
  const {
    type = 'tonal',
    width,
    height,
    className = '',
    color = 'purple-main',
    typeBtn,
    disabled = false,
    url,
    form,
  } = props
  const { onClick } = props

  let inlineWidth: string
  if (typeof width === 'string') {
    inlineWidth = width
  } else if (typeof width === 'number') {
    inlineWidth = `${width}px`
  }

  let inlineHeight: string
  if (typeof height === 'string') {
    inlineHeight = height
  } else if (typeof height === 'number') {
    inlineHeight = `${height}px`
  }

  if (url) {
    return (
      <>
        <a
          href={url}
          target="_self"
          type={typeBtn}
          style={{ width: inlineWidth, height: inlineHeight }}
          className={`${className} ${styles.btn} ${styles[type]} ${styles[color]} `}
          onClick={onClick}
          rel="noreferrer"
          // disabled={disabled}
        >
          {props.children}
        </a>
      </>
    )
  } else {
    return (
      <>
        <button
          type={typeBtn}
          style={{ width: inlineWidth, height: inlineHeight }}
          className={`${className} ${styles.btn} ${styles[type]} ${styles[color]} `}
          onClick={onClick}
          disabled={disabled}
          form={form}
        >
          {props.children}
        </button>
      </>
    )
  }
})

export default Btn
