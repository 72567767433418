import { useRef, useEffect } from 'react'

import styles from './Overlay.module.scss'
import { AnimatePresence, motion } from 'framer-motion'

interface Props {
  children: JSX.Element | JSX.Element[]
  closeOverlay: () => void
  isOverlayOpen: boolean
  className?: string
}

const Overlay = (props: Props) => {
  const { closeOverlay, className, isOverlayOpen } = props
  const overlayRef = useRef<HTMLDivElement>(null)
  // console.log('rerender overlay')
  const handleClick = (event: Event) => {
    // console.log(overlayRef.current === event.target)
    if (overlayRef.current === event.target) {
      closeOverlay()
    }
  }

  useEffect(() => {
    // этот эффект закрывает модалку при нажатии юзера за пределы мобилки
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  return (
    <AnimatePresence mode="wait">
        
      {isOverlayOpen ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className={`${styles.overlay} ${className ? className : ''}`}
            ref={overlayRef}
          >
            {props.children}
          </motion.div>
      ) : null}
    </AnimatePresence>
  )
}

export default Overlay
