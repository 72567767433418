import styles from "./CreateAdFlatParams.module.scss";
import {
  MultipleFormFilter,
  Title,
  TextInput,
  Map,
  Btn,
  InputWithSearch,
  DoubleInput,
} from "shared";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { roomSelectors } from "../../../entities/Room";
import AddRoomTooltip from "shared/ui/Tooltips/AddRoomTooltip";
import { AnimatePresence } from "framer-motion";

const rooms = [
  { id: 0, text: "Студия" },
  { id: 1, text: "1" },
  { id: 2, text: "2" },
  { id: 3, text: "3" },
  { id: 4, text: "4" },
  { id: 5, text: "5+" },
];

const bathRoom = [
  { id: "BATHROOM", text: "Ванна" },
  { id: "SHOWER", text: "Душ" },
];
const bathRoomIsCombined = [
  { id: "true", text: "Совмещённый" },
  { id: "false", text: "Раздельный" },
];

const plate = [
  { id: "ELECTRICAL", text: "Электрическая" },
  { id: "GAS", text: "Газовая" },
];

export const CreateAdFlatParams = () => {
  const inputRef = useRef(null);
  const btnRef = useRef(null);
  const room = useSelector(roomSelectors.room);

  return (
    <div className={styles.flat_params__wrapper}>
      <Title type={"h3"}>О квартире</Title>

      <div className={styles.wrapper}>
        <div className={styles.selects}>
          <MultipleFormFilter
            name={"number_rooms"}
            label={"Комнатность"}
            items={rooms}
            wrapperClass={styles.multiple}
            className={styles.multiple}
            selectedValue={room?.number_rooms}
            itemClass={styles.multiple__item}
          />
          <DoubleInput firstValue={room?.floor} secondValue={room?.max_floor} />
          <TextInput
            name={"apartment_area"}
            label={"Площадь квартиры, м²"}
            wrapperClass={styles.squareWrapper}
            width={"248px"}
            className={styles?.square}
            type={"number"}
            defaultValue={room?.apartment_area}
          />
        </div>

        <div className={styles.addressWrapper}>
          <InputWithSearch
            name={"address"}
            label={"Адрес"}
            ref={inputRef}
            onClickSearch={() => btnRef.current.click()}
            defaultValue={room?.address}
          />
          <Map
            address={room?.address}
            inputRef={inputRef}
            ref={btnRef}
            className={styles.map}
          />
          <AnimatePresence mode="wait">
            <AddRoomTooltip
              title={"Адрес можно указать прямо на карте"}
              className={styles.tooltip}
            ><>Ближайшие метро, шоссе, ж/д станции и расстояние до них определим автоматически и покажем в объявлении</></AddRoomTooltip>
          </AnimatePresence>
        </div>

        <div className={styles.bathWrapper}>
          <MultipleFormFilter
            name={"shower_type"}
            label={"Санузел"}
            items={bathRoom}
            className={styles.multipleBath}
            wrapperClass={styles.bath}
            itemClass={styles.bath__item}
            selectedValue={room?.shower_type}
          />
          <MultipleFormFilter
            name={"bathroom_is_combined"}
            items={bathRoomIsCombined}
            className={styles.multipleBath}
            wrapperClass={styles.bathIsCombined}
            itemClass={styles.bathIsCombined__item}
            selectedValue={String(room?.bathroom_is_combined)}
          />
          <MultipleFormFilter
            name={"plate_type"}
            items={plate}
            label={"Плита"}
            className={styles.multipleBath}
            wrapperClass={styles.plate}
            itemClass={styles.plate__item}
            selectedValue={room?.plate_type}
          />
        </div>
      </div>
    </div>
  );
};
