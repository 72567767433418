import { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import {
  Btn,
  Icon,
  SocialIcon,
  Text,
  useClientWidth,
  adaptiveWidth,
  Overlay,
} from 'shared'

import arrowForPlate from 'assets/icons/arrowForPlate.svg'

import styles from './ShareEntity.module.scss'

interface Props {
  url: string
  variant?: 'icon' | 'btn'
  className?: string
  // right - плашка располагается справа от кнопки
  position: 'left' | 'right'
}

const ShareEntity = (props: Props) => {
  const { url, className, variant = 'icon', position } = props
  const [urlState, setUrlState] = useState(url)
  const [isPlateActive, setActivePlate] = useState(false)
  const { getClientWidth } = useClientWidth()
  const isTabletVersion = getClientWidth() < adaptiveWidth['1040px']
  const onClosePlate = () => {
    setActivePlate(false)
  }

  const onTogglePlate = () => {
    setActivePlate((prev) => !prev)
  }

  const onOpenPlate = () => {
    setActivePlate(true)
  }

  const getPlateContent = (): JSX.Element => {
    if (isTabletVersion) {
      return (
        <>
          <Overlay
            closeOverlay={onClosePlate}
            isOverlayOpen={isPlateActive}
            // className={styles.overlay}
          >
            <div className={`${styles.plateWrapper} `}>
              {/* <img src={arrowForPlate} alt="" className={styles.arrowForPlate} /> */}
              <div className={`${styles.sharePlate} ${styles[position]}`}>
                <div className={styles.socialAndCopyWrapper}>
                  <div className={styles.urlCopy}>
                    <Text type={'paragraph'} className={styles.urlText}>
                      {url}
                    </Text>
                    <CopyToClipboard text={urlState}>
                      <Icon type="copy" variant="tonal" />
                    </CopyToClipboard>
                  </div>
                </div>
                <Icon
                  type="cross_square"
                  // variant="tonal"
                  onClick={onClosePlate}
                ></Icon>
              </div>
            </div>
          </Overlay>
        </>
      )
    } else {
      return (
        <>
          {isPlateActive ? (
            <div className={`${styles.plateWrapper} `}>
              {/* <img src={arrowForPlate} alt="" className={styles.arrowForPlate} /> */}
              <div className={`${styles.sharePlate} ${styles[position]}`}>
                <div className={styles.socialAndCopyWrapper}>
                  {/* <div className={styles.social}>
                    <SocialIcon className="telegram" type="telegram"></SocialIcon>
                  <SocialIcon className="telegram" type="vk"></SocialIcon>
                  </div> */}
                  <div className={styles.urlCopy}>
                    <Text type={'paragraph'} className={styles.urlText}>
                      {url}
                    </Text>
                    <CopyToClipboard text={urlState}>
                      <Icon type="copy" variant="tonal"></Icon>
                    </CopyToClipboard>
                  </div>
                </div>
                <Icon
                  type="cross_square"
                  // variant="tonal"
                  onClick={onClosePlate}
                ></Icon>
              </div>
            </div>
          ) : null}
        </>
      )
    }
  }

  return (
    <>
      <div
        className={
          variant === 'icon' ? styles.mainWrapper : styles.mainWrapper_allWidth
        }
      >
        <span className={className}>
          {variant === 'icon' ? (
            <Icon type="share" variant="tonal" onClick={onTogglePlate} />
          ) : (
            <>
              <Btn
                className={className}
                type="outlined"
                onClick={onTogglePlate}
              >
                Поделиться
              </Btn>
            </>
          )}
        </span>

        {getPlateContent()}
      </div>
    </>
  )
}

export default ShareEntity
