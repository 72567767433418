import { motion } from "framer-motion";
import styles from "./Toggle.module.scss";
interface Props {
  initialState: boolean | "disabled";
  onClick?: () => void;
}

const Toggle = (props: Props) => {
  const { initialState, onClick } = props;
  let toggleStateClass: string;
  if (initialState === "disabled") {
    toggleStateClass = styles.toogle_disabled;
  }
  return (
    <>
      <motion.div
        layout
        // transition={{ duration: 0.3 }}
        className={`${styles.toogle} ${toggleStateClass}`}
        data-state={initialState}
        onClick={() => {
          if (initialState !== "disabled") {
            onClick();
          }
        }}
      >
        <motion.div
          layout
          transition={{ type: "spring", stiffness: 1000, damping: 100 }}
          className={`${styles.circle}`}
        />
      </motion.div>
    </>
  );
};

export default Toggle;
