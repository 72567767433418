import { memo, useState } from 'react'
import { ContactsModal } from 'features/ContactsModal'
import { AddAndRemoveFavourites } from 'features/AddAndRemoveFavourites'
import type { FavouritiesProps } from 'features/AddAndRemoveFavourites'
import { NeighbourCard as Card } from 'entities/Neighbour'

import { INeighbour } from 'shared'
import { AnimatePresence, motion } from 'framer-motion'

interface Props {
  neighbour: INeighbour
}

const NeighbourCard = (props: Props) => {
  const { neighbour } = props
  const { tg_username, phone } = neighbour
  const [isContactsModalOpen, setContactsModalOpen] = useState(false)

  const openContactsModal = (e) => {
    e && e.stopPropagation()
    e &&e.preventDefault()
    setContactsModalOpen(true)
  }

  const closeContactsModal = (e) => {
    e && e.preventDefault()
    e && e.stopPropagation()
    setContactsModalOpen(false)
  }

  const contacts = {
    telegram: tg_username,
    phone,
    isShowPhone: neighbour.show_phone,
  }

  const addAndRemoveFavourites = (props: FavouritiesProps) => {
    return (
      <AddAndRemoveFavourites
        entity={props.entity}
        isFavorite={props.isFavorite}
        id={props.id}
        className={props.className}
        toastText="Сосед удален из избранного"
      />
    )
  }
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <AnimatePresence mode="wait">
        <Card
        key={neighbour.id}
          addAndRemoveFavourites={addAndRemoveFavourites}
          neighbour={neighbour}
          openContactsModal={ (e) => openContactsModal(e) }
        />
        <ContactsModal
          key={neighbour.id}
          contacts={contacts}
          isContactsModalOpen={isContactsModalOpen}
          closeModal={ (e) => closeContactsModal(e)}
        />
      </AnimatePresence >
    </motion.div>
  )
}

export default NeighbourCard
