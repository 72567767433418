import styles from './CreateAdConclusion.module.scss';
import { Checkbox, SingleCheckbox, Title } from "shared";
import { useAppDispatch } from "app/store";
import { toggleAgreement } from "../model/createAdConclusionSlice";
import { useState } from 'react';


export const CreateAdConclusion = () => {
  const [isAgreed, setIsAgreed] = useState(false)
  const dispatch = useAppDispatch()
  return (
    <>
      <Title type={'h3'}>Завершение</Title>
      <SingleCheckbox label='Я соглашаюсь с политикой конфиденциальности' isSelected={isAgreed} onClick={() =>{ dispatch(toggleAgreement()); setIsAgreed(!isAgreed)}}></SingleCheckbox>
    </>
  );
};



