



import { Divider } from '../Divider/Divider'
import cross_icon from 'assets/icons/tooltipCross.svg'
import corner from 'assets/icons/tooltipCorner.svg'
import styles from './AddRoomTooltip.module.scss'
import { useState } from 'react'
import { motion } from 'framer-motion'


interface Props {
  title: string
  className?: string
  children: JSX.Element
}

function AddRoomTooltip(props: Props) {
  const { title, children, className } = props
  const [tooltip, setTooltip] = useState(true)
  
  return tooltip && (
    <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.3 }}
    className={styles.tooltip + ' ' + className}>
      <img src={corner} className={styles.tooltip__corner} alt="" />
      <img src={cross_icon} className={styles.tooltip__cross} onClick={() => setTooltip(!tooltip)} alt="" />
      <p className={styles.tooltip__title}>{title}</p>
      <Divider></Divider>
      <p className={styles.tooltip__text}>{children}</p>
    </motion.div>
  );
}

export default AddRoomTooltip