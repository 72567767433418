import { useAppDispatch } from "app/store";
import { fetchMyData, myDataSelectors } from "entities/Neighbour";
import { lazy, memo, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Route,
  Routes,
  useNavigate,
  BrowserRouter as Router,
  Outlet,
  useParams,
  Navigate,
} from "react-router-dom";
import { Header, Footer, RegistrationHeader } from "widgets";

import { routes, useRegistered } from "shared";
import { checkToken } from "shared/model/AuthSlice";
import { AnimatePresence } from "framer-motion";

const NeighbourPage = lazy(() => import("./NeighbourPage/ui/NeighbourPage"));
const FindNeighbourPage = lazy(
  () => import("./FindNeighbourPage/ui/FindNeighbourPage")
);
const FindRoomPage = lazy(() => import("./FindRoomPage/ui/FindRoomPage"));
const RoomPage = lazy(() => import("./RoomPage/ui/RoomPage"));
const MyAdsPage = lazy(() => import("./MyAdsPage/ui/MyAdsPage"));
const OneMyAdPage = lazy(() => import("./OneMyAdPage/ui/OneMyAdPage"));
const FavouritesPage = lazy(() => import("./FavouritesPage/ui/FavouritesPage"));
const MyAccountPage = lazy(() => import("./MyAccountPage/ui/MyAccountPage"));
const EditAccountPage = lazy(
  () => import("./EditAccountPage/ui/EditAccountPage")
);
const CreateAdPage = lazy(() => import("./CreateAd/ui/CreateAdPage"));
const AuthPage = lazy(() => import("./AuthPage/ui/AuthPage"));
const NotFoundPage = lazy(() => import("./NotFoundPage/ui/NotFoundPage"));
const IncorrectEmailPage = lazy(
  () => import("./IncorrectEmailPage/ui/IncorrectEmailPage")
);
const RegistrationEntryPage = lazy(
  () => import("./RegistrationEntryPage/ui/RegistrationEntryPage")
);
const RegistrationStepsPage = lazy(
  () => import("./RegistrationStepsPage/RegistrationStepsPage")
);

export const Routing = memo(() => {
  function RegistrationLayout() {
    return (
      <>
        <RegistrationHeader />
        <Outlet />
        <Footer />
      </>
    );
  }
  function CommonLayout() {
    return (
      <>
        <Header />
        <AnimatePresence mode="wait">
          <Outlet />
        </AnimatePresence>
        <Footer />
      </>
    );
  }
  return (
    <Routes>
      <Route path={routes.findNeighbourPage} element={<CommonLayout />}>
        <Route index element={<FindNeighbourPage />} />
        <Route
          path={routes.neighbourPage.fullPath}
          element={<NeighbourPage />}
        />
        <Route path={routes.roomPage.parentPage} element={<FindRoomPage />} />
        <Route path={routes.roomPage.fullPath} element={<RoomPage />} />
        <Route path={routes.myAdsPage.allAdsPage} element={<MyAdsPage />} />
        <Route path={routes.myAdsPage.oneAdPage} element={<OneMyAdPage />} />
        <Route path={routes.favouritesPage} element={<FavouritesPage />} />
        <Route path={routes.myAccount} element={<MyAccountPage />} />
        <Route path={routes.createAdPage} element={<CreateAdPage />} />
        <Route path={routes.editePage} element={<CreateAdPage />} />
        {/* <Route path={routes.editAccountPage} element={<EditAccountPage />}/> */}
        <Route
          path={routes.editAccountSteps}
          element={<RegistrationStepsPage title="Редактирование" />}
        />
        <Route
          path={routes.editAccountPage}
          element={<Navigate to="/edit-account/1" />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Route>

      <Route path={routes.emailError} element={<RegistrationLayout />}>
        <Route index element={<IncorrectEmailPage />} />
      </Route>

      <Route path={routes.registration} element={<RegistrationLayout />}>
        <Route index element={<RegistrationEntryPage />} />
        <Route
          path={routes.registrationSteps}
          element={<RegistrationStepsPage title="Регистрация" />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Route>

      <Route path={routes.authPage} element={<AuthPage />} />
    </Routes>
  );
});
