import { useState, memo, useRef, useEffect } from 'react'
import { Text, Title, Icon, Btn, ModalWraper } from 'shared'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import styles from './ContactsModal.module.scss'
import { AnimatePresence, motion } from 'framer-motion'

interface Props {
  contacts: {
    // тг без собаки. Пример, "soldatov_sem"
    telegram: string
    phone: string
    isShowPhone: boolean
  }
  isContactsModalOpen: boolean
  closeModal: (e?) => void
}

const ContactsModal = memo((props: Props) => {
  const { contacts, isContactsModalOpen, closeModal } = props
  const { isShowPhone, telegram, phone } = contacts
  const [telegramText, setTelegramText] = useState(telegram)
  const [phoneText, setPhoneText] = useState(phone)
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 2 }}
    >
      <AnimatePresence mode='wait'>
        <ModalWraper isModalOpen={isContactsModalOpen} closeModal={ (e) => closeModal(e)}>
          <motion.div
          initial={{ opacity: 0 }}  
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className={styles.modal__wrapper}>
            <Title type="h3">Контакты</Title>
  
            <div className={styles.contacts}>
              <div className={styles.contact__item}>
                <Icon
                  type="telegram"
                  variant="filled"
                  className={styles.icon}
                ></Icon>
                <Text type="paragraph">{telegram}</Text>
  
                <CopyToClipboard text={telegram}>
                  <button className={styles.btn__copy}>
                    <Icon type="copy" />
                  </button>
                </CopyToClipboard>
              </div>
              {/* <div className={styles.contact__item}>
                <Icon
                  type="phone"
                  variant="filled"
                  className={styles.icon}
                ></Icon>
                <Text type="paragraph">
                  {isShowPhone && !!phone ? phone : 'Пользователь скрыл номер'}
                </Text>
                {isShowPhone && !!phone ? (
                  <>
                    <CopyToClipboard text={phone}>
                      <button className={styles.btn__copy}>
                        {' '}
                        <Icon type="copy" />
                      </button>
                    </CopyToClipboard>
                  </>
                ) : null}
              </div> */}
            </div>
            <Btn
              url={`https://t.me/${contacts.telegram}`}
              width={'100%'}
              type="tonal"
              className={styles.btn__telegram}
            >
              Написать в Telegram
            </Btn>
          </motion.div>
        </ModalWraper>
      </AnimatePresence>
    </motion.div>
  )
})

export default ContactsModal
