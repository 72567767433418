// Этот компонент должен быть фичей, но почему-то у меня нет прав доступа перенести его туда, лол

import styles from "./CreateAdRoomParams.module.scss";
import {
  Title,
  TextInput,
  MultipleFormFilter,
  Checkbox,
  ImgInput,
  SingleCheckbox,
} from "shared";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { roomSelectors } from "../../../entities/Room";
import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { AnimatePresence } from "framer-motion";
import AddRoomTooltip from "shared/ui/Tooltips/AddRoomTooltip";

const periodFilter = [
  { id: "2", text: "2 месяца" },
  { id: "6", text: "Полгода" },
  { id: "12", text: "Год" },
];

const sex = [
  { id: "MAN", text: "Парень" },
  { id: "WOMAN", text: "Девушка" },
  { id: "NO_MATTER", text: "Не важно" },
];

interface Props {
  files?: any;
  setFiles?: any;
}

export const CreateAdRoomParams: FC<Props> = (props) => {
  const { files, setFiles } = props;
  const room = useSelector(roomSelectors.room);
  const roomStatus = useSelector(roomSelectors.roomStatus);
  const { id } = useParams();
  const [isSelectedDeposit, setSelectedDeposit] = useState(false);
  const [depositValue, setDepositValue] = useState("");
  const [isSelectedUtilities, setSelectedUtilities] = useState(false);
  const [utilitiesValue, setUtilitiesValue] = useState("");
  const { setValue } = useFormContext();
  const onInputDeposit = (e) => {
    setDepositValue(e.target.value);
    if (e.target.value) {
      setSelectedDeposit(false);
    }
  };
  const onInputUtilities = (e) => {
    setUtilitiesValue(e.target.value);
    if (e.target.value) {
      setSelectedUtilities(false);
    }
  };

  const onSetDeposit = () => {
    setSelectedDeposit((deposit) => !deposit);
    if (depositValue && !isSelectedDeposit) {
      setValue("deposit", 0);
    }
  };
  const onSetUtilities = () => {
    setSelectedUtilities((utility) => !utility);
    if (utilitiesValue && !isSelectedUtilities) {
      setValue("public_utilities", 0);
    }
  };

  return (
    <div className={styles.room}>
      <Title type="h3">О комнате</Title>

      <div className={styles.wrapper}>
        <div className={styles.priceWrapper}>
          <div className={styles.inputWrapper}>
            <MultipleFormFilter
              label={"Минимальный срок аренды"}
              items={periodFilter}
              name={"minimum_rental_period"}
              wrapperClass={styles.multiple}
              className={styles.price}
              itemClass={styles.multiple__item}
              selectedValue={room?.minimum_rental_period}
            />
          </div>

          <div className={styles.inputWrapper}>
            <TextInput
              name={"room_area"}
              label={"Площадь комнаты, м²"}
              type={"number"}
              width="100%"
              wrapperClass={styles.squareWrapper}
              className={styles.squareInputWrapper}
              defaultValue={room?.room_area}
            />
          </div>
        </div>

        <div className={styles.priceWrapper}>
          <div className={styles.price}>
            <TextInput
              name={"price"}
              label={"Цена за аренду в месяц, ₽"}
              type={"number"}
              wrapperClass={styles.priceInput}
              width="100%"
              className={styles.priceInput}
              defaultValue={room?.price}
            />
            {/* <Checkbox
                name="public_utilities"
                defaultChecked={room?.public_utilities}
              >
                Коммунальные включены
              </Checkbox> */}
            <SingleCheckbox
              onClick={onSetUtilities}
              isSelected={isSelectedUtilities}
              label="Коммунальные включены"
            />
          </div>
          <div className={styles.deposit}>
            <TextInput
              name="deposit"
              label="Залог"
              type="number"
              width="100%"
              className={styles.priceInput}
              onHandleChange={onInputDeposit}
              value={depositValue}
            />
            <SingleCheckbox
              onClick={onSetDeposit}
              isSelected={isSelectedDeposit}
              label="Без залога"
            />
          </div>
        </div>
      </div>
      <div className={styles.imgInput}>
        {/*@ts-ignore*/}
        <ImgInput
          name={"photo"}
          files={files}
          setFiles={setFiles}
          photos={room?.photo}
        />
        <AnimatePresence mode="wait">
            <AddRoomTooltip
              title={"+100% просмотров у объявлений с фото"}
              className={styles.tooltip}
            ><>Объявления без фото не интересны арендаторам</></AddRoomTooltip>
          </AnimatePresence>
      </div>
    </div>
  );
};
