import { motion } from 'framer-motion'
import styles from './Container.module.scss'

interface Props {
  children: JSX.Element | JSX.Element[] | string
  className?: string
}

const Container = (props: Props) => {
  const { children, className } = props
  return (
    <>
      <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className={`${styles.container} ${className}`}>{children}</motion.div>
    </>
  )
}

export default Container
