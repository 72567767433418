// для чекбоксов типа не курит/не пьет в реге
import { TAgainstWishes } from "entities/EditAccount/types";
import { ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import { Text } from "shared";
import { IMultipleItem } from "shared/ui/Filters/MultipleFilter/MultipleFilter";
import mark_icon from "../../../../assets/icons/functional_icons/mark_icon.svg";

import styles from "./CheckboxesInput.module.scss";
import { ErrorMessage } from "@hookform/error-message";

interface IRegEnd {
  text: ReactElement;
  value: string;
}
interface TAgainst {
  against_alcohol: boolean;
  against_smoking: boolean;
}

interface Props {
  name: string;
  label?: string | JSX.Element;
  items: IMultipleItem<string>[];
  onClick?: (e) => void;
  defaultValue?: TAgainst;
  isRequired?: boolean;
}

const CheckboxesInput = (props: Props): JSX.Element => {
  let { label, items, onClick, name, defaultValue, isRequired } = props;
  const { register, formState, watch } = useFormContext();
  const { errors } = formState;
  let elements = items.map((item: IMultipleItem<string>, ind) => {
    let isChecked =
      item.id === "against_alcohol"
        ? defaultValue.against_alcohol
        : defaultValue.against_smoking;
    return (
      <div key={item.id}>
        <label
          id={item.id}
          className={`${styles.single_item} ${
            item.isSelected || isChecked
              ? styles.single_active
              : styles.single_item
          }`}
        >
          <input
            id={item.id}
            name={item.id}
            type="checkbox"
            onClick={() => onClick(item.id)}
            {...register(name, {
              required: {
                value: isRequired,
                message: "Обязательное поле",
              },
            })}
            defaultChecked={isChecked}
            style={{ display: "none" }}
          />
          <div
            className={`${styles.single_checkbox} ${
              errors[name] ? styles.single_checkbox_invalid : null
            }`}
          >
            <img src={mark_icon} alt="mark_icon" />
          </div>
          {item.text}
        </label>
      </div>
    );
  });
  return (
    <>
      <div className={styles.component}>
        <Text type="small" color="secondary-black">
          {label}
        </Text>
        <div className={styles.wrapper}>{elements}</div>
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <Text type="small" className={styles.error}>
              {message}
            </Text>
          )}
        />
      </div>
    </>
  );
};

export default CheckboxesInput;
