import { Link } from 'react-router-dom'
import { IconProps, routes as routesService } from 'shared'
import {
  Icon,
  Title,
  Text,
  UtilitiesPlate,
  Plate,
  SquarePhoto,
  Btn,
  Avatar,
  getMyAdStatus,
} from 'shared'

import styles from './RoomCard.module.scss'
import ruble_symbol from 'assets/icons/ruble_symbol.svg'

import { IRoom } from 'shared'
import { getRoomFirstRow } from 'entities/Room/lib/getRoomFirstRow'
import { getApartmentRow } from 'entities/Room/lib/getApartmentRow'
import {
  getDepositPrice,
  getMonthPrice,
  isDeposit,
  isPublicUtilities,
} from 'entities/Room/lib/getPriceRow'

import {
  getAge,
  getFullName,
  getGender,
  getLabelBadHabits,
  getWhatNeighbour,
  useClientWidth,
  adaptiveWidth,
} from 'shared'
import React from 'react'
import { PlateCardStatus } from 'entities/MyAds'
import { motion } from 'framer-motion'

interface FavouritiesProps {
  entity: 'rooms'
  isFavorite: boolean
  id: number
  toastText?: string
  className?: string
}

interface Props {
  room: IRoom
  addAndRemoveFavourites?: (props: FavouritiesProps) => JSX.Element
}

const RoomCard = (props: Props) => {
  const { room, addAndRemoveFavourites } = props
  // landlord - арендодатель
  const { photo, landlord, routes } = room
  const imgRoom = photo.length > 0 ? photo[0].image : null
  const imgLandlord =
    landlord.photo.length > 0
      ? landlord.photo[landlord.photo.length - 1].image
      : null
  const { getClientWidth } = useClientWidth()
  const age = getAge(landlord.birthday)
  const utilitiesPlate = isPublicUtilities(room) ? (
    <div className={styles.info__plate}>
      <UtilitiesPlate>
        {' '}
        <Icon type="tag" className={styles.info__icon} />
        <Text
          type={
            getClientWidth() < adaptiveWidth['800px'] ? 'small' : 'paragraph'
          }
          weight="regular"
          color="white-main"
        >
          С учетом коммуналки
        </Text>
      </UtilitiesPlate>
    </div>
  ) : null

  const metroPlate = routes[0]?.destination ? (
    <>
      {' '}
      <Plate className={styles.plate}>
        {routes.map((route, ind) => {
          if (route.destination) {
            return (
              <div key={ind} className={styles.plate__metro}>
                <div className={styles.plate__metro__name}>
                  <Icon type="pixel" className={styles.plate__metro__icon} />
                  <Text type="paragraph">{route.destination}</Text>
                </div>
                <div className={styles.divider}></div>
                <div className={styles.plate__metro__time}>
                  <Icon
                    type={route.type === 'IN_PUBLIC' ? 'car' : 'walker'}
                    size="large"
                    className={
                      route.type === 'IN_PUBLIC'
                        ? styles.plate__transport
                        : styles.plate__walker
                    }
                  />
                  <Text type="paragraph">{route.time} мин</Text>
                </div>
              </div>
            )
          }
        })}
      </Plate>
    </>
  ) : null

  const labelBadHabbits = getLabelBadHabits(landlord)
  const labelDesiredNeighbour = getWhatNeighbour(landlord)
  const linkToRoomPage = room.is_my_room
    ? `${routesService.myAdsPage.allAdsPage}/${room.id}`
    : `${routesService.roomPage.parentPage}/${room.id}`

  const habbitsAndDesiredNeighbourRow = (
    <div className={styles.card__preferences}>
      {labelBadHabbits ? (
        <div className={styles.card__preference}>
          <div className={styles.card__icons}>
            {landlord.against_smoking ? <Icon type={'no-smoking'} /> : null}
            {landlord.against_alcohol ? <Icon type={'no-alcohol'} /> : null}
          </div>
          <Text type="small">{labelBadHabbits}</Text>
        </div>
      ) : null}

      <div className={styles.card__preference}>
        {labelDesiredNeighbour ? (
          <>
            <Icon type="pixel" />
            <Text type="small">{labelDesiredNeighbour}</Text>
          </>
        ) : null}
      </div>
    </div>
  )
  const hiddenMyAddClass =
    room.is_my_room && getMyAdStatus(room) === 'HIDDEN' ? styles.hiddenMyAd : ''

  if (getClientWidth() < adaptiveWidth['800px']) {
    return (
      <>
        <AdaptiveRoomCard
          habbitsAndDesiredNeighbourRow={habbitsAndDesiredNeighbourRow}
          utilitiesPlate={utilitiesPlate}
          room={room}
          linkToRoomPage={linkToRoomPage}
          metroPlate={metroPlate}
          addAndRemoveFavourites={addAndRemoveFavourites}
        />
      </>
    )
  } else {
    return (
      <>
        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}>
          <Link 
          className={`${styles.room} ${hiddenMyAddClass} ${styles.none__underline}`} to={linkToRoomPage}
          >
              <div className={styles.left__column}>
                <SquarePhoto
                  className={styles.squarePhoto}
                  size={240}
                  img={imgRoom}
                />
                <Btn width={'100%'} className={styles.btn}>
                  <Text type="paragraph" color="white-main">
                    Подробнее
                  </Text>
                </Btn>
              </div>
  
            <div className={styles.room__info}>
              {room.is_my_room ? <PlateCardStatus room={room} /> : null}
  
              <div>
                <div className={styles.info}>
                  <div className={styles.first__row}>
                      <Title type={'h4'}>{getRoomFirstRow(room)}</Title>{' '}
                    {room.is_my_room
                      ? null
                      : addAndRemoveFavourites({
                          entity: 'rooms',
                          isFavorite: room.is_favorite,
                          id: room.id,
                        })}
                  </div>
  
                    <Text type="paragraph" color="secondary-black">
                      {getApartmentRow(room)}
                    </Text>
                    <div className={styles.info__wrapper}>
                      <div className={styles.info__row}>
                        <Title type={'h4'}>
                          {getMonthPrice(room)} <img src={ruble_symbol} alt="" />{' '}
                          в месяц
                        </Title>
                        {isDeposit(room) ? (
                          <>
                            {' '}
                            <div className={styles.divider}></div>
                            <Title type={'h4'}>
                              {getDepositPrice(room)}{' '}
                              <img src={ruble_symbol} alt="" /> залог
                            </Title>
                          </>
                        ) : null}
                      </div>
                      {utilitiesPlate}
                    </div>
  
                    {metroPlate}
                </div>
              </div>
                <div className={styles.card}>
                  <div className={styles.card__info}>
                    <Avatar
                      img={imgLandlord}
                      className={styles.avatar}
                      abbreviationOfName={
                        landlord.last_name[0] + landlord.first_name[0]
                      }
                    />
                    <div>
                      <Text type="paragraph" weight="bold">
                        {getFullName(landlord)}
                      </Text>
                      <div className={styles.card__dots}>
                        <Text type="small">{getGender(landlord)}</Text>
                        {age ? 
                        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                          <div className={styles.divider}></div>
                          <Text type="small">{age}</Text>
                        </div> : null}
  
                        {landlord.job ? (
                          <>
                            <div className={styles.divider}></div>
                            <Text type="small">{landlord.job}</Text>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {habbitsAndDesiredNeighbourRow}
                </div>
            </div>
          </Link>
        </motion.div>
      </>
    )
  }
}

interface IAdaptiveProps {
  habbitsAndDesiredNeighbourRow: JSX.Element
  utilitiesPlate: JSX.Element
  metroPlate: JSX.Element
  linkToRoomPage: string
  room: IRoom
  addAndRemoveFavourites?: (props: FavouritiesProps) => JSX.Element
}

const AdaptiveRoomCard = (props: IAdaptiveProps) => {
  const {
    room,
    addAndRemoveFavourites,
    utilitiesPlate,
    habbitsAndDesiredNeighbourRow,
    metroPlate,
    linkToRoomPage,
  } = props
  const { photo, landlord } = room
  const imgRoom = photo.length > 0 ? photo[0].image : null
  const imgLandlord =
    landlord.photo.length > 0
      ? landlord.photo[landlord.photo.length - 1].image
      : null
  const hiddenMyAddClass =
    room.is_my_room && getMyAdStatus(room) === 'HIDDEN' ? styles.hiddenMyAd : ''

  const icon = (propsCustom: IconProps) => {
    if (room.is_my_room) {
      return null
    }
    return addAndRemoveFavourites({
      entity: 'rooms',
      isFavorite: room.is_favorite,
      id: room.id,
      className: propsCustom.iconClassName,
    })
  }
  const age = getAge(landlord.birthday)
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Link
      className={styles.none__underline + ' ' + styles.room + ' ' + hiddenMyAddClass} to={linkToRoomPage}>
        <div className={styles.squarePhotoWrapper}>
          <SquarePhoto
            className={styles.squarePhoto}
            size={240}
            img={imgRoom}
            icon={icon}
            locationIcon="right-top"
            abbreviationOfName={landlord.last_name[0] + landlord.first_name[0]}
          />
        </div>
          <div className={styles.room__info}>
            <div className={styles.first__row}>
              <Title className={styles.h4_Mobile} type={'h4'}>
                {getRoomFirstRow(room)}
              </Title>
              <Text type="small" color="secondary-black">
                {getApartmentRow(room)}
              </Text>
            </div>

            <div className={styles.info__wrapper}>
              <div className={styles.info__row}>
                <Title className={styles.h4_Mobile} type={'h4'}>
                  {getMonthPrice(room)} <img src={ruble_symbol} alt="" /> в
                  месяц
                </Title>
                {isDeposit(room) ? (
                  <>
                    {' '}
                    <div className={styles.divider}></div>
                    <Title className={styles.h4_Mobile} type={'h4'}>
                      {getDepositPrice(room)} <img src={ruble_symbol} alt="" />{' '}
                      залог
                    </Title>
                  </>
                ) : null}
              </div>
              {utilitiesPlate}
            </div>

            {metroPlate}
          </div>

          <div className={styles.card}>
            <div className={styles.aboutPerson}>
              <Text color="secondary-black" type="mobile-small">
                Предпочтения по жилью
              </Text>
              <div className={styles.card__info}>
                <Avatar
                  img={imgLandlord}
                  className={styles.avatar}
                  abbreviationOfName={
                    landlord.last_name[0] + landlord.first_name[0]
                  }
                />
                <div>
                  <Text type="paragraph" weight="bold">
                    {getFullName(landlord)}
                  </Text>
                  <div className={styles.card__dots}>
                    <Text type="small">{getGender(landlord)}</Text>
                   {age ? 
                    <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                      <div className={styles.divider}></div>
                        <Text type="small">{age}</Text>
                    </div> : null}
                    {landlord.job ? (
                      <>
                        {' '}
                        <div className={styles.divider}></div>
                        <Text type="small">{landlord.job}</Text>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            {habbitsAndDesiredNeighbourRow}
          </div>
        {room.is_my_room ? <PlateCardStatus room={room} /> : null}{' '}
          <Btn width={'100%'} className={styles.btn}>
            <Text type="paragraph" color="white-main">
              Подробнее
            </Text>
          </Btn>
      </Link>
    </motion.div>
  )
}

export default React.memo(RoomCard)
