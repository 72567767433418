import { CreateAdRoomParams } from 'widgets/CreateAdRoomParams'
import { FormProvider, useForm } from 'react-hook-form'
import { CreateAdFlatParams } from 'features/CreateAdFlatParams'
import {
  _apiUrl,
  Btn,
  Divider,
  ErrorMessage,
  routes,
  useToken,
  useRegistered,
  Text,
} from 'shared'
import styles from './CreateAdForm.module.scss'
import { CreateAdAdditionals } from 'features/CreateAdAdditionals/ui/CreateAdAdditionals'
import { useEffect, useState } from 'react'
import {
  CreateAdConclusion,
  createAdConclusionSelectors,
} from 'entities/CreateAdConclusion'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'app/store'
import {
  postCreateAdForm,
  postPhotosAdForm,
  putCreateAdForm,
  resetCustomForm,
} from '../model/createAdFormSlice'
import { createAdFormSelectors } from '../index'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { fetchOneRoom, roomSelectors } from 'entities/Room'
import { resetRoomData as resetMyAd } from 'entities/MyAds/model/myAdsSlice'
import { myAdsSelectors } from 'entities/MyAds'
import { resetRoomData } from 'entities/Room/model/roomSlice'

export const CreateAdForm = () => {
  const { id } = useParams()

  const dispatch = useAppDispatch()
  const { getToken } = useToken()
  const token = getToken()
  const roomStatus = useSelector(roomSelectors.roomStatus)
  const myRoomAdStatus = useSelector(myAdsSelectors.myRoomAdStatus)
  const errorStatus = useSelector(roomSelectors.errorStatus)
  const { redirectToAuth } = useRegistered()
  const methods = useForm({ mode: 'all' })
  const { handleSubmit, formState } = methods
  const [files, setFiles] = useState([])

  useEffect(() => {
    dispatch(resetCustomForm())
    if (id) {
      dispatch(resetRoomData())
      dispatch(fetchOneRoom({ id, token }))
    } else {
      dispatch(resetRoomData())
      dispatch(resetMyAd())
      setFiles([])
      methods.reset()
    }
  }, [])

  const navigate = useNavigate()

  const isAgreed = useSelector(createAdConclusionSelectors.isAgreed)
  const room = useSelector(roomSelectors.room)
  const status = useSelector(createAdFormSelectors.status)
  const photoStatus = useSelector(createAdFormSelectors.photoStatus)
  const errorMessage = useSelector(createAdFormSelectors.errorMessage)
  const photosId = useSelector(createAdFormSelectors.photosId)
  const address = useSelector(createAdFormSelectors.address)
  const address_x = useSelector(createAdFormSelectors.address_x)
  const address_y = useSelector(createAdFormSelectors.address_y)
  const routesToMetro = useSelector(createAdFormSelectors.routes)
  const errorAdStatus = useSelector(createAdFormSelectors.errorStatus)

  const onSubmit = async (data) => {
    const { checked, photo, bathroom_is_combined, deposit, ...rest } = data
    const sendingData = {
      ...rest,
      bathroom_is_combined: Boolean(bathroom_is_combined),
      address_x,
      address_y,
      address,
      deposit: deposit === '' || !deposit ? 0 : deposit,
      routes: routesToMetro,
    }

    if (id) {
      await dispatch(putCreateAdForm({ token, body: sendingData, id }))

      if (status === 'error') {
        navigate(routes.myAdsPage.allAdsPage)
      } else {
        navigate(routes.myAdsPage.allAdsPage)
      }
    } else {
      const postCreateAdResult = await dispatch(
        postCreateAdForm({ token, body: sendingData })
      )
      // @ts-ignore
      const roomId = postCreateAdResult.payload

      if (status === 'error') {
        return
      } else if (status === 'success') {
        methods.reset()
        navigate(routes.myAdsPage.allAdsPage)
      }

      const photosBody = {
        room: roomId,
        photos: photosId,
      }

      await dispatch(postPhotosAdForm({ token, body: photosBody }))

      if ((await photoStatus) === 'error') {
        return
      }

      await navigate(routes.myAdsPage.allAdsPage)
    }
  }

  if (errorStatus === 401) {
    redirectToAuth()
  }

  if ((!id && roomStatus === 'idle' && myRoomAdStatus === 'idle') || id) {
    return (
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          {id && room?.moderation_status === 'REJECTED' ? (
            <ErrorMessage
              className={styles.errorMessageWrapper}
              title={'Объявление не прошло проверку'}
            >
              {/* {errorMessage} */}
              <Text type="paragraph" className={styles.errorMessageWrapper}>
                {room?.comment_from_moderator}
              </Text>
            </ErrorMessage>
          ) : null}
          <CreateAdRoomParams files={files} setFiles={setFiles} />
          <Divider className={styles.divider} />
          <CreateAdFlatParams />
          <Divider className={styles.divider} />
          <CreateAdAdditionals />
          <Divider className={styles.divider} />
          {id ? (
            <div className={styles.btnEditWrapper}>
              <Btn className={styles.btn} typeBtn={'submit'} type={'tonal'}>
                Сохранить и опубликовать
              </Btn>
              <Btn
                className={styles.btn}
                typeBtn={'button'}
                type={'outlined'}
                onClick={() => navigate(routes.myAdsPage.allAdsPage)}
              >
                Выйти без сохранения
              </Btn>
            </div>
          ) : (
            <div className={styles.adConclusion__wrapper}>
              <CreateAdConclusion />
              <div className={styles.btnWrapper}>
                <Btn
                  className={styles.btn}
                  disabled={isAgreed === false || status === 'loading' || !formState.isValid}
                  typeBtn="submit"
                  type="tonal"
                >
                  Опубликовать объявление
                </Btn>
              </div>
            </div>
          )}
          {errorMessage ? (
            <ErrorMessage
              title={'Произошла ошибка'}
              className={styles.errorMessageWrapper}
            >
              {errorMessage}
            </ErrorMessage>
          ) : null}
        </form>
      </FormProvider>
    )
  }
}
