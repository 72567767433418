import { IRoom } from 'shared'

const getApartmentRow = (room: IRoom): string | null => {
  const { apartment_area, number_rooms, room_area, floor, max_floor } = room

  if (room_area === 0) return `на ${floor}/${max_floor} этаже`

  if (number_rooms === 1) {
    return `в 1-о комнатной квартире, ${apartment_area} м²`
  } else if (number_rooms > 1 && number_rooms <= 4) {
    return `в ${number_rooms}-х комнатной квартире, ${apartment_area} м²`
  } else if (number_rooms === 5) {
    return `в ${number_rooms}-ти комнатной квартире, ${apartment_area} м²`
  } else if (number_rooms > 5 && number_rooms < 10) {
    return `в ${number_rooms}-и комнатной квартире, ${apartment_area} м²`
  } else if (!number_rooms || !apartment_area) {
    return null
  }
}

export { getApartmentRow }
