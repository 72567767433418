import { memo, useEffect, useState, useRef } from 'react'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'app/store'
import { Navigation } from 'features/Navigation'
// import { fetchMyPhoto } from '../model/headerSlice'
import { fetchMyData, myDataSelectors } from 'entities/Neighbour'

import {
  Logo,
  Icon,
  Avatar,
  Btn,
  Text,
  useToken,
  useClientWidth,
  Container,
  getFullName,
  useRegistered,
  Title,
  SocialIcon,
} from 'shared'
import { routes, adaptiveWidth } from 'shared'
import styles from './Header.module.scss'
import localStorage from 'redux-persist/es/storage'

const Header = memo(() => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const myData = useSelector(myDataSelectors.myData)
  const myDataStatus = useSelector(myDataSelectors.myDataStatus)
  const errorMessage = useSelector(myDataSelectors.errorMyData)
  const errorStatus = useSelector(myDataSelectors.errorStatus)
  const { getToken } = useToken()
  let content: Element | JSX.Element
  const { getClientWidth } = useClientWidth()
  const [isNavigation, setNavigation] = useState(false)
  const headerRef = useRef(null)

  const openNavigation = () => {
    setNavigation(true)
  }

  const changeNavigation = () => {
    setNavigation(!isNavigation)
  }

  const closeNavigation = () => {
    setNavigation(false)
  }

  let avaPhoto: string | null

  useEffect(() => {
    dispatch(fetchMyData({ token: getToken() }))
    localStorage.getItem('link').then((data) => {
      if (data === 'hidden') setLinkHidden(true);
    });
  }, [])
  const [isLinkHidden, setLinkHidden] = useState(false);
  const { redirectToAuth } = useRegistered()

  if (errorStatus === 401) {
    redirectToAuth()
  }

  if (myDataStatus === 'success' && myData.photo.length > 0) {
    avaPhoto = myData.photo[myData.photo.length - 1].image
  } else if (
    myDataStatus === 'error' ||
    (myData && myData.photo.length === 0)
  ) {
    avaPhoto = null
  }

  function hideLink() {
    localStorage.setItem('link', 'hidden');
    setLinkHidden(true);
  }
  
  if (
    getClientWidth() > adaptiveWidth['1040px'] &&
    myDataStatus === 'success'
  ) {
    return (
      <>
        <header className={styles.header__container}>
          <div className={styles.header} ref={headerRef}>
          <div className={styles.header__wrapper}>
            {/* <div className={styles.header__side}> */}
              <Link
                to={routes.findNeighbourPage}
                className={styles.text__decoration_no}
              >
                <Logo />
              </Link>
              <ul className={styles.header__list}>
                <li>
                  <NavLink
                    to={routes.findNeighbourPage}
                    className={({ isActive }) =>
                      isActive
                        ? `${styles.text__decoration_no} ${styles.header__li_active}`
                        : styles.text__decoration_no
                    }
                  >
                    <Text type={'paragraph'} className={styles.header__li}>
                      Соседи
                    </Text>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={routes.roomPage.parentPage}
                    className={({ isActive }) =>
                      isActive
                        ? `${styles.text__decoration_no} ${styles.header__li_active}`
                        : styles.text__decoration_no
                    }
                  >
                    <Text type={'paragraph'} className={styles.header__li}>
                      Квартиры и комнаты
                    </Text>
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    to={routes.myAdsPage.allAdsPage}
                    className={({ isActive }) =>
                      isActive &&
                      location.pathname === routes.myAdsPage.allAdsPage
                        ? `${styles.text__decoration_no} ${styles.header__li_active}`
                        : styles.text__decoration_no
                    }
                  >
                    <Text type={'paragraph'} className={styles.header__li}>
                      Мои объявления
                    </Text>
                  </NavLink>
                </li> */}
              </ul>
            {/* </div> */}

            <div
              className={`${styles.header__side} ${styles.header__side_right}`}
            >
              {/* <Btn
                width={214}
                height={40}
                onClick={() => navigate(routes.createAdPage)}
              >
                Разместить комнату
              </Btn> */}
              <Link
                to={routes.favouritesPage}
                className={styles.text__decoration_no}
              >
                <Icon type={'favorites-tonal'} variant="tonal" size="large" />
              </Link>

              <Link
                to={routes.myAccount}
                className={styles.text__decoration_no}
              >
                <Avatar
                  img={avaPhoto}
                  className={`${styles.avatar}`}
                  abbreviationOfName={
                    myData.last_name[0] + myData.first_name[0]
                  }
                />
              </Link>
            </div>
          </div>
          </div>

          <div className={styles.header__link} style={{display: isLinkHidden ? 'none' : 'flex'}}>
            <Title type={'h4'} color='white-main'>Получай новые анкеты соседей и советы по аренде жилья</Title>
            <div className={styles.header__btn__wrapper}>
              <Btn 
              type='outlined'
              url='https://t.me/+W_5-qDcKSJ81NjZi'
              children={
                <div className={styles.header__btn}>
                  <SocialIcon type={'telegram'} className={styles.header__icon_wrapper}/>
                  {/* <Icon type='telegram' size='small' className={styles.header__icon_wrapper}/> */}
                  <Text type='paragraph'>Подписаться на канал</Text>
                </div>
              }
              ></Btn>
              <Icon type="cross_rounded" onClick={hideLink} className={styles.header__cross}/>
            </div>
          </div>
        </header>
      </>
    )
  } else if (
    getClientWidth() <= adaptiveWidth['1040px'] &&
    myDataStatus === 'success'
  ) {
    const content = (
      <>
        <Navigation
          isNavigation={isNavigation}
          myData={myData}
          img={avaPhoto}
          onCloseNavigation={closeNavigation}
        ></Navigation>
        <header className={styles.header} ref={headerRef}>
          <Container>
            <div className={styles.header__wrapper}>
              <Link
                to={routes.favouritesPage}
                className={styles.text__decoration_no}
              >
                <Icon
                  type={'favorites-tonal'}
                  variant="tonal"
                  className={`${styles.icon}`}
                />
              </Link>
              <Link
                to={routes.findNeighbourPage}
                className={styles.text__decoration_no}
              >
                <Logo></Logo>
              </Link>
              <Icon
                type={!isNavigation ? 'burger' : 'cross_rounded'}
                variant="tonal"
                className={`${styles.icon}`}
                onClick={changeNavigation}
              />
            </div>
          </Container>
          <div className={styles.header__link} style={{display: isLinkHidden ? 'none' : 'flex'}}>
              <p className={styles.header__title} >Новые анкеты и советы по аренде</p>
              <div className={styles.header__btn__wrapper}>
                <Btn 
                type='outlined'
                url='https://t.me/+W_5-qDcKSJ81NjZi'
                children={
                  <div className={styles.header__btn}>
                    <Icon type='telegram' size='small' className={styles.header__icon_wrapper}/>
                    <Text type='paragraph'>Подписаться на канал</Text>
                  </div>
                }
                ></Btn>
                <Icon type="cross_rounded" onClick={hideLink}/>
              </div>
          </div>
        </header>
      </>
    )
    return <> {content}</>
  }
})

export default Header
