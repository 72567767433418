import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Icon, useToken, Toast, useRegistered } from 'shared'

import { useAppDispatch } from 'app/store'
import { roomSelectors } from 'entities/Room'
import { neighbourSelector, setFavotiteNeighbour } from 'entities/Neighbour'
import { favouritesSelectors, toogleFavourites } from '../index'
import type { InitDataAddFourities } from '../index'

import styles from './AddAndRemoveFavourites.module.scss'
// import {  } from 'entities/Neighbour/model/neighbourSlice'

export interface FavouritiesProps {
  entity: 'neighbours' | 'rooms'
  isFavorite: boolean
  id: number
  className?: string
  toastText?: string
}

const AddAndRemoveFavourites = (props: FavouritiesProps) => {
  const { entity, isFavorite, id, className, toastText } = props
  const [isFavoriteState, setIsFavoriteState] = useState(isFavorite)
  const { redirectToAuth } = useRegistered()
  const dispatch = useAppDispatch()
  const { getToken } = useToken()
  const { setFavotiteRoom } = roomSelectors
  const errorStatus = useSelector(favouritesSelectors.errorStatus)
  const neighbourLikeChangeStatus = useSelector(
    favouritesSelectors.neighbourLikeChangeStatus
  )
  const roomLikeChangeStatus = useSelector(
    favouritesSelectors.roomLikeChangeStatus
  )
  const [isToastVisible, setToastVisible] = useState(false)
  const closeToast = () => {
    setToastVisible(false)
    setIsFavoriteState(true)
  }
  const handleToggleFavourites = (e) => {
    e && e.preventDefault()
    e && e.stopPropagation()
    if (isFavoriteState) {
      setToastVisible(true)
      setTimeout(() => {
        setToastVisible(false)
      }, 5000)
    } else {
      setToastVisible(false)
    }
    const initData: InitDataAddFourities = {
      token: getToken(),
      id,
      entity,
      typeRequest: !isFavoriteState ? 'like' : 'remove_like', // если текущее состояние не в избранном, то отправляем like
    }
    dispatch(toogleFavourites(initData)) // отправляем запрос
    setIsFavoriteState((prev) => !prev) // меняем иконку
    if (entity === 'rooms') {
      dispatch(
        setFavotiteRoom({
          id,
          is_favorite: !isFavoriteState,
        })
      )
    } else if (entity === 'neighbours') {
      dispatch(
        setFavotiteNeighbour({
          id,
          is_favorite: !isFavoriteState,
        })
      )
    }
  }
  if (errorStatus === 401) {
    redirectToAuth()
  }
  const toast = (
    <Toast
      isCancelBtn={true}
      label={toastText}
      isVisible={isToastVisible}
      closeToast={closeToast}
      className={styles.toast}
    />
  )
  return (
    <>
      <Icon
        type={isFavoriteState ? 'favorites-filled' : 'favorites-tonal'}
        variant="tonal"
        className={className}
        onClick={ (e) => handleToggleFavourites(e)}
      ></Icon>
      {toast}
    </>
  )
}

export default AddAndRemoveFavourites
