import { useSelector } from 'react-redux'
import {
  Text,
  Title,
  SocialIcon,
  Container,
  useClientWidth,
  adaptiveWidth,
} from 'shared'

import styles from './Footer.module.scss'

const Footer = () => {
  const { getClientWidth } = useClientWidth()

  return (
    <>
      <footer className={styles.footer}>
        <Container>
          <div className={styles.footer__content}>
            <div className={styles.emailAndLinks}>
              <div className="">
                <Title
                  type={
                    getClientWidth() <= adaptiveWidth['800px'] ? 'h4' : 'h2'
                  }
                  url="mailto:homeet@styleru.org"
                  color="purple-main"
                  // className={styles.noLink}
                >
                  homeet@styleru.org
                </Title>
                <Text
                  type="paragraph"
                  color="secondary-black"
                  url="https://t.me/go00golo0"
                  className={styles.technical__support}
                >
                  Техническая поддержка
                </Text>
              </div>

              <div className={styles.row}>
                <div className={styles.links}>
                  <a href="https://info.homeet.styleru.org/" target='_blank' rel="noreferrer" className={styles.link}>
                    <Text type="paragraph" className="link">
                      О сервисе
                    </Text>
                  </a>
                  <a href="https://www.hse.ru/data_protection_regulation" target='_blank' rel="noreferrer" className={styles.link}>
                    <Text type="paragraph" className="link">
                      Политика конфиденциальности
                    </Text>
                  </a>
                  
                </div>
                <div className={styles.social}>
                  <a href="https://t.me/homeetHSE" target='_blank' style={{textDecoration: 'none'}} rel="noreferrer"><SocialIcon type="telegram" size="small"></SocialIcon></a>
                  {/* <SocialIcon type="vk" size="small"></SocialIcon> */}
                </div>
              </div>
            </div>

            <div className={styles.divider}></div>
            <div className={styles.text}>
              <Text className={styles.text_1} type="small" color="grey">
                Предложение не является публичной офертой. <br /> Сервис не
                несет ответственности за действия пользователей, достоверность
                информации и т.д.
              </Text>
              <Text type="small" color="grey">
                Сделано в <a href="https://t.me/styleruorg" target='_blank' rel="noreferrer" className={styles.link} style={{color: '#aaaaaa'}}>Стилеру</a>
              </Text>
            </div>
          </div>
        </Container>
        <div className={styles.line}></div>
      </footer>
    </>
  )
}

export default Footer
